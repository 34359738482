import React, { useEffect, useState } from 'react'
import { 
    Grid, 
    Paper, 
    Typography, 
    TextField, 
    Button, 
    LinearProgress, 
    ButtonGroup, 
    Zoom, 
    Divider,
    FormControl,
    InputLabel,
    Select,
    FormHelperText,
    InputAdornment,
    IconButton,
    MenuItem,
    FormControlLabel,
    Switch,
} from '@mui/material';
import { apiFetch } from '../../service/apiFetch';
import { useNavigate, useParams } from 'react-router-dom';
import { progressBarWidth, TransitionLeft } from '../../helpers/utils';
import { useUsers } from '../../hooks/users';
import { ADMIN, SUPER_ADMIN } from '../../constantes/mainConstantes';
import { handleFormError } from '../../service/security/formError';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ProgressBar from '../../components/ProgressBar';
import UploadImage from '../../components/UploadItem';
import UploadPdf from '../../components/UploadPdf';

export default function EditUserAccount({
    phoneResponsive,
    setText,
    setTransition,
    setSeverity,
    setOpenSnackbar,
    darkState,
    zoom,
    user 
}) {
    const { id } = useParams()
    const [userEdit, setUserEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [role, setRole] = useState('')
    const [error, setError] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [style, setStyle] = useState(false)
    const [photo, setPhoto] = useState(false);
    const [portrait, setPortrait] = useState(false);
    const [pcFunction, setPcFunction] = useState('')
    const [pcDivision, setPcDivision] = useState('')
    const [reachable, setReachable] = React.useState(false);
    let navigate = useNavigate()

    const {
        updateUser,
    } = useUsers()

    useEffect(() => {
        (async () => {
            const userFetch = await apiFetch(`/users/${id}`)

            if (userFetch) {
                setPcFunction(userFetch.function || '')
                setPcDivision(userFetch.division || '')
                setReachable(userFetch.reachable)
                setRole(userFetch.roles[0])
                setUserEdit(userFetch)
            } else {
                setText((userFetch && userFetch['hydra:description']) || 'Erreur lors de la création')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            }
        })()
    }, [id, setOpenSnackbar, setTransition, setSeverity, setText])

    const handleEdit = async (e) => {
        e.preventDefault()
        setLoading(true)

        let createPhoto = null
        if (photo) {
            let dataPhoto = new FormData()
            dataPhoto.append('file', photo && photo[0])
            createPhoto = await apiFetch('/photo_objects', {
                method: 'POST',
                body: dataPhoto
            }, true)
        }
        if (createPhoto && createPhoto['hydra:description']) {
            setText(createPhoto['hydra:description'])
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
            setLoading(false)
            return null
        }

        let createPortrait = null
        if (portrait) {
            let dataPortrait = new FormData()
            dataPortrait.append('file', portrait && portrait[0])
            createPortrait = await apiFetch('/user_portrait_objects', {
                method: 'POST',
                body: dataPortrait
            }, true)
        }
        if (createPortrait && createPortrait['hydra:description']) {
            setText(createPortrait['hydra:description'])
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
            setLoading(false)
            return null
        }

        let data = new FormData(e.target)
        const email = data.get('email')
        const firstName = data.get('firstName')
        const lastName = data.get('lastName')
        const pass = data.get('password')
        const phone = data.get('phone')
        const description = data.get('description')

        data = user.roles[0] === SUPER_ADMIN ? 
        { 
            email: email,
            firstName: firstName,
            lastName: lastName,
            roles: [role],
            password: pass,
            phone: phone,
            reachable: reachable,
            function: pcFunction,
            division: pcDivision,
            description: description,
        } :
        user.roles[0] === ADMIN ? 
        {
            email: email,
            firstName: firstName,
            lastName: lastName,
            roles: [role],
            password: pass,
            phone: phone,
            reachable: reachable,
            function: pcFunction,
            division: pcDivision,
            description: description,
        } : 
        { 
            email: email,
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            reachable: reachable,
            function: pcFunction,
            division: pcDivision,
            description: description,
        }

        if (photo) {
            data.photo = createPhoto['@id']
        }

        if (portrait) {
            data.portrait = createPortrait['@id']
        }

        const updatedUser = await updateUser(id, data)

        if (updatedUser && updatedUser.uuid) {
            setText('L\'utilisateur a été modifié')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setTimeout(() => {
                navigate('/users')
            }, 1000)
        }  else if (updatedUser.violations) {
            const errorField = handleFormError(updatedUser,
                [
                    'password',
                    'roles',
                    'firstName',
                    'lastName',
                    'email',
                    'password',
                    'phone',
                    'description',
                    'function'
                ]
            )
            setError(errorField)
            setText('Erreur lors de la modification')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        } else {
            setText((updatedUser && updatedUser['hydra:description']) || 'Erreur lors de la modification')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setLoading(false)
    }

    const handleBack = () => {
        navigate('/users')
    }

    const handleChangeRole = (e) => {
        setRole(e.target.value)
    }

    const handleClickPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleChangeFunction = (e) => {
        setPcFunction(e.target.value)
    }

    const handleChangeDivision = (e) => {
        setPcDivision(e.target.value)
    }

    const handleChangeProgressBar = (e) => {
        progressBarWidth(e, setStyle)
    }

    const handleChange = (event) => {
        setReachable(event.target.checked);
    };

    return (
        <Zoom in={zoom}>
            <div>
                <Paper
                    sx ={{
                        p: '10px 20px'
                    }}
                >
                    {userEdit && <form noValidate onSubmit={handleEdit} autoComplete="off">
                        <Typography variant='h5' color={darkState ? 'secondary' : "primary"}>
                            Modifier le profil de {userEdit.firstName} {userEdit.lastName}
                        </Typography>
                        <Divider style={{marginTop: 20}} />
                        <Grid container spacing={3} style={{marginTop: 20}}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    color={darkState ? 'secondary' : "primary"}
                                    error={!!error.lastName}
                                    helperText={error.lastName}
                                    id="lastName"
                                    name="lastName"
                                    label="Nom"
                                    defaultValue={userEdit.lastName}
                                    sx={{
                                        width: 300,
                                        mb: 3,
                                    }} 
                                /><br />
                                <TextField
                                    color={darkState ? 'secondary' : "primary"}
                                    error={!!error.firstName}
                                    helperText={error.firstName}
                                    id="firstName"
                                    name="firstName"
                                    label="Prénom"
                                    defaultValue={userEdit.firstName}
                                    sx={{
                                        width: 300,
                                        mb: 3,
                                    }} 
                                /><br />
                                {user.roles && (user.roles[0] === SUPER_ADMIN || user.roles[0] === ADMIN) && <FormControl
                                    color={darkState ? 'secondary' : "primary"} 
                                    error={!!error.roles}
                                >
                                    <InputLabel htmlFor="age-native-simple">Rôle</InputLabel>
                                    <Select
                                        defaultValue={userEdit.roles[0]}
                                        onChange={handleChangeRole}
                                        inputProps={{
                                            name: 'age',
                                            id: 'age-native-simple',
                                        }}
                                        sx={{
                                            width: 300,
                                            mb: 3,
                                        }}
                                    >
                                        <MenuItem aria-label="None" value="" ><em>Sélectionner un rôle</em></MenuItem>
                                        <MenuItem value={'ROLE_USER'}>Rôle Utilisateur</MenuItem>
                                        <MenuItem value={'ROLE_ADMIN'}>Rôle Admin</MenuItem>
                                        <MenuItem value={'ROLE_INTERVENTION_MANAGER'}>Rôle responsable des interventions</MenuItem>
                                        <MenuItem value={'ROLE_SPECIALIST_MANAGER'}>Rôle responsable des spécialistes</MenuItem>
                                    </Select>
                                </FormControl>}
                                <FormHelperText>{error.roles}</FormHelperText>
                                <TextField
                                    color={darkState ? 'secondary' : "primary"}
                                    error={!!error.phone}
                                    helperText={error.phone}
                                    id="phone"
                                    name="phone"
                                    label="Téléphone"
                                    defaultValue={userEdit.phone}
                                    sx={{
                                        width: 300,
                                        mb: 3,
                                    }}
                                /><br />
                                <div style={{marginTop: 30}}>
                                    <UploadPdf
                                        setPdf={setPortrait}
                                        title={'Ajouter un portrait'}
                                    />
                                </div><br />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    color={darkState ? 'secondary' : "primary"}
                                    error={!!error.email}
                                    helperText={error.email}
                                    id="email"
                                    name="email"
                                    label="Email"
                                    defaultValue={userEdit.email}
                                    sx={{
                                        width: 300,
                                        mb: 3,
                                    }}
                                /><br />
                                <TextField 
                                    id="password" 
                                    name="password" 
                                    label="Nouveau mot de passe"
                                    color={darkState ? 'secondary' : "primary"}
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    color='inherit'
                                                    onClick={handleClickPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                
                                    error={!!error.password} helperText={error.password}
                                    onChange={handleChangeProgressBar}
                                    sx={{
                                        width: 300,
                                        mb: 3,
                                    }}
                                />
                                <ProgressBar style={style} customStyle={{width: 300, marginTop: -23, marginLeft: 0}} />
                                <FormControl style={{marginTop: 37}} color={darkState ? 'secondary' : "primary"} error={!!error.function}>
                                    <InputLabel htmlFor="function-simple">Fonction</InputLabel>
                                    <Select
                                        value={pcFunction}
                                        onChange={handleChangeFunction}
                                        inputProps={{
                                            name: 'function',
                                            id: 'function-simple',
                                        }}
                                        sx={{
                                            width: 300,
                                            mb: 3,
                                        }}
                                    >
                                        <MenuItem aria-label="None" value="" ><em>Sélectionner une fonction</em></MenuItem>
                                        <MenuItem value={'Directrice'}>Directrice</MenuItem>
                                        <MenuItem value={'Directrice Administrative'}>Directrice Administrative</MenuItem>
                                        <MenuItem value={'Responsable Communication et Cheffe de projets'}>
                                            Responsable Communication et Cheffe de projets
                                        </MenuItem>
                                        <MenuItem value={'Responsable Developpement Informatique'}>
                                            Responsable Developpement Informatique
                                        </MenuItem>
                                        <MenuItem value={'Responsable Grands Comptes publics'}>
                                            Responsable Grands Comptes publics
                                        </MenuItem>
                                        <MenuItem value={'Responsable Transition Numérique'}>
                                            Responsable Transition Numérique
                                        </MenuItem>
                                        <MenuItem value={'Responsable Pédagogique'}>Responsable Pédagogique</MenuItem>
                                        <MenuItem value={'Responsable Exploitation'}>Responsable Exploitation</MenuItem>
                                        <MenuItem value={'Développeur Informatique'}>Développeur Informatique</MenuItem>
                                        <MenuItem value={'Développeur Mobile'}>Développeur Mobile</MenuItem>
                                        <MenuItem value={'Chargé(e) de Projets Intervention'}>Chargé(e) de Projets Intervention</MenuItem>
                                        <MenuItem value={'Chargé(e) d\'Affaires'}>Chargé(e) d'Affaires</MenuItem>
                                        <MenuItem value={'Chargé(e) de Projets Ligne d\'écoute'}>
                                            Chargé(e) de Projets Ligne d'écoute
                                        </MenuItem>
                                        <MenuItem value={'Assistante Administrative'}>Assistante Administrative</MenuItem>
                                        <MenuItem value={'Assistante Pédagogique'}>Assistante Pédagogique</MenuItem>
                                        <MenuItem value={'Assistante de Communication'}>Assistante de Communication</MenuItem>
                                        <MenuItem value={'Assistante Projets'}>Assistante Projets</MenuItem>
                                        <MenuItem value={'Assistante administrative MP/AO'}>Assistante administrative MP/AO</MenuItem>
                                        <MenuItem value={'Assistante de Gestion facturation'}>Assistante de Gestion facturation</MenuItem>
                                        <MenuItem value={'Coordinateur Intervention'}>Coordinateur Intervention</MenuItem>
                                        <MenuItem value={'Graphiste'}>Graphiste</MenuItem>
                                        <MenuItem value={'Comptable'}>Comptable</MenuItem>
                                        <MenuItem value={'Chargée du Développement et de l\'Animation du réseau des psychologues'}>
                                            Chargée du Développement et de l'Animation du réseau des psychologues
                                        </MenuItem>
                                        <MenuItem value={'Gestionnaire Coaching Digital'}>Gestionnaire Coaching Digital</MenuItem>
                                    </Select>
                                </FormControl><br />
                                <FormControl style={{marginTop: 10}} color={darkState ? 'secondary' : "primary"} error={!!error.function}>
                                    <InputLabel htmlFor="division-simple">Pôle</InputLabel>
                                    <Select
                                        value={pcDivision}
                                        onChange={handleChangeDivision}
                                        inputProps={{
                                            name: 'division',
                                            id: 'division-simple',
                                        }}
                                        sx={{
                                            width: 300,
                                            mb: 3,
                                        }}
                                    >
                                        <MenuItem aria-label="None" value="" ><em>Sélectionner un pôle</em></MenuItem>
                                        <MenuItem value={'Direction'}>Direction</MenuItem>
                                        <MenuItem value={'Accueil'}>Accueil</MenuItem>
                                        <MenuItem value={'Exploitation'}>Exploitation</MenuItem>
                                        <MenuItem value={'Interventions / Audits'}>Interventions / Audits</MenuItem>
                                        <MenuItem value={'Formation'}>Formation</MenuItem>
                                        <MenuItem value={'Commercial'}>Commercial</MenuItem>
                                        <MenuItem value={'Ligne d\'écoute'}>Ligne d'écoute</MenuItem>
                                        <MenuItem value={'Communication'}>Communication</MenuItem>
                                        <MenuItem value={'Réseau psy / recrutement'}>Réseau psy / recrutement</MenuItem>
                                        <MenuItem value={'Projets'}>Projets</MenuItem>
                                        <MenuItem value={'Informatique'}>Informatique</MenuItem>
                                        <MenuItem value={'Appels d\'offre'}>Appels d'offre</MenuItem>
                                        <MenuItem value={'Comptabilité'}>Comptabilité</MenuItem>
                                        <MenuItem value={'Wellmonday'}>Wellmonday</MenuItem>
                                    </Select>
                                </FormControl>
                                <div style={{marginTop: 30}}>
                                    <UploadImage setPhoto={setPhoto} title={'Ajouter une photo'} />
                                </div>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={reachable}
                                            onChange={handleChange}
                                            name="reachable"
                                            color={darkState ? 'secondary' : "primary"}
                                        />
                                    }
                                    label="Joignable"
                                />
                            </Grid>
                        </Grid>
                        <ButtonGroup
                            size={phoneResponsive ? 'small' : 'medium'}
                            color={darkState ? 'secondary' : "primary"}
                            variant="contained"
                            aria-label="contained primary button group"
                        >
                            <Button
                                type='submit'
                                disabled={loading}
                            >
                                Enregistrer
                            </Button>
                            <Button onClick={handleBack}>
                                Retour
                            </Button>
                        </ButtonGroup>
                    </form>}
                </Paper>
                {(loading || !userEdit) && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
            </div>
        </Zoom>
    )
}